<div [formGroup]="visitFamilyFormGroup" style="overflow-y:auto; overflow-x:hidden; max-height: 95vh; margin-left: 4px"
     class="bg-white p-4" [ngClass]="{'p-2': !isInDialog}">
  <h4 class="mb-4">Ajouter un rendez-vous</h4>
  <!-- Lieu -->
  <div class="row form-group">
    <label class="text-start star col-4 col-sm-3">Lieu</label>
    <app-select [group]="visitFamilyFormGroup" [items]="places" class="col-7 col-sm-5"
                fcName="place"></app-select>
  </div>
  <!-- Visiteur -->
  <div class="row form-group">
    <label class="star col-4 col-sm-3 text-start">Visiteur</label>
    <app-select [group]="visitFamilyFormGroup" [items]="visitors" class="col-7 col-sm-5"
                fcName="visitor"></app-select>
  </div>
  <!-- Date -->
  <div class="row form-group">
          <label class="text-start star col-4 col-sm-3">Date</label>
          <div class="input-group col-7 col-sm-5">
            <input [matDatepicker]="visitDate"
                    [max]="maxDate" autocomplete="off" formControlName="date"
                    class="form-control">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary form-control" (click)="visitDate.open()">
                <mat-icon>calendar_month</mat-icon>
              </button>
            </div>
          </div>
          <mat-datepicker
            #visitDate
            [startAt]="endDate">
    </mat-datepicker>
  </div>
  <!-- Heure de début -->
  <div class="row">
    <label class="text-start star col-5 col-sm-3" style="margin-top: 1.2rem;">Heure de début</label>
    <mat-form-field class="col-3">
      <input formControlName="hourStart" matInput max="23" min="0"
             placeholder="Heure"
             type="number">
    </mat-form-field>
    <span class="text-center" style="margin-top: 1.2rem;">:</span>
    <mat-form-field class="col-3">
      <input formControlName="minuteStart" matInput max="59" min="0"
             placeholder="Minute"
             type="number">
    </mat-form-field>
  </div>

  <hr>

  <!-- Parent -->
  <label class="star mb-3">Responsable</label>
  <div class="row col-md-12">
    <div class="d-flex flex-column flex-sm-wrap flex-fill flex-sm-row">
      <div *ngFor="let parent of parents" [formGroup]="visitFamilyFormGroup"
           class="custom-control custom-radio col-md-4">
        <input class="custom-control-input"
               [value]="parent.id"
               formControlName="parent"
               id="idRadioBtn{{parent.id}}"
               name="parent"
               type="radio">
        <label class="custom-control-label"
               for="idRadioBtn{{parent.id}}">{{parent.firstName}} {{parent.lastName}}</label>
      </div>
    </div>
  </div>

  <hr>

  <!-- Jeunes -->
  <label class="star mb-3">Jeune(s) concerné(s)</label>
  <div *ngFor="let young of youngs" class="row col-md-12 form-group">
    <mat-checkbox [formGroup]="visitFamilyFormGroup" color="primary" formControlName="youngSelection{{young.id}}"
                  class="col-md-4">
      {{young.firstName}} {{young.lastName[0] | uppercase}}.
    </mat-checkbox>
    <app-select-multiple [group]="visitFamilyFormGroup" *ngIf="isYoungSelected(young.id)"
                         [items]="reasons" class="col-md-8"
                         fcName="reason{{young.id}}" [textLabel]="'Motif(s)'"></app-select-multiple>
  </div>

  <!-- Boutons -->
  <div *ngIf="isSaveError === true" class="alert alert-danger" role="alert">
    Erreur lors de l'enregistrement !
  </div>
  <mat-dialog-actions class="d-flex flex-row align-items-center justify-content-center">
    <button class="btn btn-primary m-2" mat-button
            type="submit" (click)="onBtnCreateVisitClick()"
            [disabled]="!isVisitFamilyFormValid() || isCreateBtnLoading">
      <span class="spinner-border spinner-border-sm" *ngIf="isCreateBtnLoading"></span>
      Enregistrer
    </button>
    <button class="m-2" mat-button (click)="onBtnCancelClick()">
      Annuler
    </button>
  </mat-dialog-actions>
</div>


